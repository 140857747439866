.block_jumbo {
	position: relative;
    padding: 0 0 20px;
	z-index: 0;

	@include media-breakpoint-up(md) {
		position: relative;
		padding: 0;
		.slide-placeholder { height: auto; }
		.jumbo_conversion {
			position: absolute;
			top: 12%;
			left: 0; right: 0;
			width: 100%;
			z-index: 1;
		}
		.jumbo_cover {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			z-index: 1;
			transform: translateY(15px);
		}
		.btn { @include btn--md; }
		.btn-convert { @include btn--lg; }
	}
	@include media-breakpoint-up(lg) {
		.jumbo_conversion { top: 20%; }
	}

}

	.jumbo_conversion {
		font-family: $font-family-sans-serif;
		text-align: center;
	}

		.conversion-title {
			margin-top: 20px;
			.title-small {
				font-size: 75%;
				font-weight: $body-weight-bold;
				letter-spacing: -0.0175em;
				line-height: 1em;
			}
			@include media-breakpoint-up(md) {
				@include transform(skew(-15deg));
				@include font-size(32);
				span {
					display: inline-block;
					padding: 2px 10px 3px;
					background-color: rgba(black, 0.8);
					color: $white;
				}
			}
			@include media-breakpoint-up(lg) {
				@include font-size(36);
			}
			@include media-breakpoint-up(xl) {
				@include font-size(42);
			}
		}

		.conversion-list {
			letter-spacing: -0.0275em;
			li { margin-bottom: 0 !important; }
			li:not(:last-child) { margin-right: 15px; }
			span {
				display: block;
				padding: 0 0 0 22px;
				background: url("/_/images/icons/cm_secondary.png") left center no-repeat;
				background-size: 15px;
			}
			@include media-breakpoint-up(md) {
				li {
					@include transform(skew(-15deg));
					padding: 5px 20px 5px 15px;
					@include font-size(18);
					text-transform: uppercase;
					letter-spacing: -0.0325em;
					background-color: theme(secondary, base);
					background-color: rgba(black, 0.8);
					color: $white;
					&:not(:last-child) { margin-right: 25px; }
					span { background-image: url("/_/images/icons/cm_white.png"); }
				}
			}
			@include media-breakpoint-up(lg) {
				li { @include font-size(20); }
			}
			@include media-breakpoint-up(xl) {
				li { @include font-size(24); }
			}
		}