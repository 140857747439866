@mixin btn($bg: $color-link, $bgTo: $color-hover, $color: $white) {
    background-color: darken($bg, 5%);
    color: $color;
    &:hover,
    &:focus {
		background-color: $bgTo;
        color: $color;
    }    
}


.btn {
	display: inline-block;
    padding: 12px 15px;
    font-family: $font-family-sans-serif;
    font-weight: $body-weight-bold;
    line-height: 1em;
    text-decoration: none;
    text-transform: uppercase;
	cursor: pointer;
	border-radius: 3px;
	@include transform(scale(0.95));
    @include transition(all ease-in-out 200ms);

	small { display: block; line-height: 1em }

	&:hover {
		@include transform(scale(1));
		@include transition(all ease-in-out 200ms);
	}

	&:focus {
		outline: 4px solid rgba(0,0,0,0.25);
	}

}

	%btn_text, .btn-text { @include btn($color-text); }
	%btn_link, .btn-link { @include btn($color-link); }
	%btn_primary, .btn-primary { @include btn($color-primary, $color-secondary, white); }
	%btn_secondary, .btn-secondary { @include btn($color-secondary); }
	%btn_highlight, .btn-highlight { @include btn($color-highlight); }
	%btn_accent, .btn-accent { @include btn($color-accent); }
	%btn_success, .btn-success { @include btn($color-success); }
	%btn_danger, .btn-danger { @include btn($color-danger); }
	%btn_info, .btn-info { @include btn($color-info); }
	%btn_review, .btn-review { @include btn($color-review); }
	%btn_warning, .btn-warning { @include btn($color-warning); }

	%btn_orange, .btn-orange { @include btn($orange); }

	%btn-block, .btn-block { display: block; text-align: center; }

	.btn-icon {
		vertical-align: bottom;
		img {
			display: inline-block;
			width: 22px;
			margin-right: 0.75em;
			vertical-align: middle;
		}
		&.btn--lg img { width: 28px; }
		&.btn--sm img { width: 18px; }
	}

	%btn--sm, .btn--sm { padding: 8px 10px; @include font-size(14); }
		@mixin btn--sm { padding: 8px 10px; @include font-size(14); }

	%btn--md, .btn--md { padding: 12px 15px; @include font-size(16); }
		@mixin btn--md { padding: 12px 15px; @include font-size(16); }

	%btn--lg, .btn--lg { padding: 15px 25px; @include font-size(18); }
		@mixin btn--lg { padding: 15px 25px; @include font-size(18); }

	%btn--wide, .btn--wide { display: block; width: 100% }
		@mixin btn--wide { display: block; width: 100% }
