.main {
	h3, .h3 { color: $color-secondary; }
}
	
	.sub_menu_wrapper {
		@include media-breakpoint-up(md) {
			float: right;
			margin: 0 0 15px 15px;
			.widget {
				display: inline-block;
				width: auto;
			}
		}
	}


.media {
	
	&_content {

		h2 {
			font-weight: $heading-weight-bolder;
			b {
				display: block;
				font-size: 75%;
				font-weight: $heading-weight-normal;
				letter-spacing: 0.2em;
			}
		}

		@include media-breakpoint-up(lg) {
			.row {
				align-items: flex-start;
				&.flipped {
					.wrap:nth-child(1) { order: 2; }
					.wrap:nth-child(2) { order: 1; }
				}
			}
			.media_text > :first-child {
				margin-top: 0;
			}
		}

	}

	&_image {
		picture {
			display: block;
			max-width: 100%;
			img {
				width: auto;
				max-width: 100%;
				padding: 5px;
				border: 2px solid #ddd;
				background-color: white;
			}
		}
		@include media-breakpoint-up(lg) {
			@include make-col(1,2);
		}
		@include media-breakpoint-up(xl) {
			@include make-col(7,16);
		}
	}

	&_text {
		@include media-breakpoint-up(lg) {
			@include make-col(1,2);
		}
		@include media-breakpoint-up(xl) {
			@include make-col(9,16);
		}
	}

}