.hidden { display: none !important; }

.hidden--xs { @include media-breakpoint-down(xs) { display: none !important; } }
.hidden--sm { @include media-breakpoint-only(sm) { display: none !important; } }
.hidden--md { @include media-breakpoint-only(md) { display: none !important; } }
.hidden--lg { @include media-breakpoint-only(lg) { display: none !important; } }
.hidden--xl { @include media-breakpoint-up(xl) { display: none !important; } }

[class*="visible-"] { display: none !important; }
.visible--xs { @include media-breakpoint-down(xs) { display: block !important; } }
.visible--sm { @include media-breakpoint-only(sm) { display: block !important; } }
.visible--md { @include media-breakpoint-only(md) { display: block !important; } }
.visible--lg { @include media-breakpoint-only(lg) { display: block !important; } }
.visible--xl { @include media-breakpoint-up(xl) { display: block !important; } }

.db { display: block !important; }

.mt0 { margin-top: 0 !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt25 { margin-top: 25px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mb0 { margin-bottom: 0 !important; }
.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; }

.pt0 { padding-top: 0 !important; }
.pt10 { padding-top: 10px !important; }
.pt15 { padding-top: 15px !important; }
.pt20 { padding-top: 20px !important; }
.pt25 { padding-top: 25px !important; }
.pt30 { padding-top: 30px !important; }
.pb0 { padding-bottom: 0 !important; }
.pb10 { padding-bottom: 10px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb25 { padding-bottom: 25px !important; }
.pb30 { padding-bottom: 30px !important; }

.no-transform { transform: none !important; }

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.ff--serif { font-family: $font-family-serif !important; }
.ff--sans { font-family: $font-family-sans-serif !important; }

.txt--lg { font-size: 115% !important; }
.txt--md { font-size: inherit !important; }
.txt--sm { font-size: 85% !important; }

.bw0 { font-weight: $body-weight-normal !important; }
.bw1 { font-weight: $body-weight-bold !important; }
.bw2 { font-weight: $body-weight-bolder !important; }

.hw0 { font-weight: $heading-weight-normal !important; }
.hw1 { font-weight: $heading-weight-bold !important; }
.hw2 { font-weight: $heading-weight-bolder !important; }

.ls--sm { letter-spacing: -0.0375em; }
.ls--rg { letter-spacing: 0; }
.ls--lg { letter-spacing: 0.275em; }

.lh1 { line-height: 1.1em !important; }
.lh125 { line-height: 1.25em !important; }

.txt--left { text-align: left !important; }
.txt--right { text-align: right !important; }
.txt--center { text-align: center !important; }

.txt--up { text-transform: uppercase !important; }
.txt--cap { text-transform: capitalize !important; }



.color--text { color: $color-text !important; }
.color--white { color: $white !important; }
.color--primary { color: $color-primary !important; }
.color--secondary { color: $color-secondary !important; }
.color--highlight { color: $color-highlight !important; }
.color--link { color: $color-link !important; }
.color--review { color: $color-review !important; }
.color--success { color: $color-success !important; }
.color--danger { color: $color-danger !important; }
.color--warning { color: $color-warning !important; }
.color--info { color: $color-info !important; }

.bg--text { background-color: $color-text !important; }
.bg--white { background-color: $white !important; }
.bg--primary { background-color: $color-primary !important; }
.bg--secondary { background-color: $color-secondary !important; }
.bg--highlight { background-color: $color-highlight !important; }
.bg--link { background-color: $color-link !important; }
.bg--review { background-color: $color-review !important; }
.bg--success { background-color: $color-success !important; }
.bg--danger { background-color: $color-danger !important; }
.bg--warning { background-color: $color-warning !important; }
.bg--info { background-color: $color-info !important; }
.bg--transparent { background-color: transparent !important; }
.bg--body { background-color: $body-bg !important;  }

.gdfancybg--text { @include gdfancy-bg($color-text); }
.gdfancybg--white { @include gdfancy-bg($white); }
.gdfancybg--primary { @include gdfancy-bg($color-primary); }
.gdfancybg--secondary { @include gdfancy-bg($color-secondary); }
.gdfancybg--highlight { @include gdfancy-bg($color-highlight); }
.gdfancybg--review { @include gdfancy-bg($color-review); }
.gdfancybg--success { @include gdfancy-bg($color-success); }
.gdfancybg--danger { @include gdfancy-bg($color-danger); }
.gdfancybg--warning { @include gdfancy-bg($color-warning); }
.gdfancybg--info { @include gdfancy-bg($color-info); }