#login {
	display: none;
	@include media-breakpoint-up(md) {
		display: block;
		position: absolute;
		top: -25px;
		right: 0;
	}
}

	.btn-login {
		padding: 6px 8px 5px;
		@include font-size(16);
		line-height: 1em;
	}

.banner {
	padding: 15px;
	text-align: center;
	background-color: $white;

	.container { position: relative; }

	@include media-breakpoint-up(md) {

		padding: 30px 15px;
		text-align: left;

		.banner_branding {
			@include make-col(4); }

		.banner_best {
			@include make-col(4);
		}

			.banner-best { margin-top: 10px; }

		.banner_contact {
			text-align: right;
			@include make-col(4); }

			.banner-contact { margin: 0; }

		.banner_menu {
			margin-top: 15px;
			@include make-col(12); }

	}

	@include media-breakpoint-up(lg) {
		.row { align-items: flex-start; }
		.banner_branding {
			@include make-col(4);
			.logo.banner-logo { max-width: 285px; }
		}
		.banner_best { @include make-col(5); }
			.banner-best { margin: 0 15px 0 0; padding: 0; }
		.banner_contact { @include make-col(3); }
		.banner_best,
		.banner_contact { margin-top: 10px; }
		.banner_menu {
			@include make-col(8);
			margin: -35px 0 0 auto; 
		}
	}

}